import * as React from "react";
import constants from "constants/index";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";

import MediaQuery from "../../../layout/MediaQuery";
import Tagline from "../../../texts/Tagline";
import Content from "../../../layout/Content/Content";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Button from "../../../buttons/Button";

const Container = Styled.div`
  margin: 6rem 0 6rem 0;
  overflow: hidden; // fix for columns overflow

   ${Media.greaterThan("small")`
     margin: 10rem 0 10rem 0;
  `}
  
  ${Media.greaterThan("medium")`
     margin: 15rem 0 15rem 0;
  `}
`;

const Columns = Styled.div`
  ${Media.greaterThan("700px")`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 42rem;
  `}
  
  ${Media.greaterThan("large")`
    height: 51.3rem;
  `}
`;

const Column = Styled.div`
  position: relative;
  
  ${Media.greaterThan("700px")`
    width: 45%;
    min-width: 0;
  `}
`;

const ColumnIllustration = Styled(Column)`
  width: 100%;
  height: 42rem;
  display: flex;
  align-items: center;
  margin-top: 15rem;
      
  ${Media.greaterThan("700px")`
      height: auto;
      width: 50%;
      margin-top: 0;
  `}
  
  position: relative;
`;

const Text = Styled.div`
  width: 43rem;
  max-width: 100%;

  ${Media.greaterThan("large")`
    width: calc(43rem + 9.5rem);
    padding-left: 9.5rem;
  `}
`;

const DescriptionExtended = Styled(Description)`
  margin-bottom: 2rem;

  ${Media.greaterThan("700px")`
    margin-bottom: 3rem;
  `}
`;

const ImageMobile = Styled(Image)`
  width: 29rem;
  margin: 0 0 3rem 0;
`;

const TaglineExtended = Styled(Tagline)`
  color: #7f7f7f;
`;

const ImageWrapper = Styled.div`
  position: absolute;
  left: 50%;
  top: 38%;
  transform: translate(-50%, -50%);
  
  ${Media.greaterThan("700px")`
    top: 55%;
  `}
  
  ${Media.greaterThan("medium")`
    top: 50%;
  `}
`;

const ImageDesktop = Styled(Image)`
  width: 28.8rem;
  max-width: calc(100% - 0.75rem);

  ${Media.greaterThan("700px")`
    max-width: 100%;
  `}

  ${Media.greaterThan("large")`
    width: 35rem;
  `}
`;

const Box = Styled.div`
  width: 32rem;
  height: 32rem;
    margin: 0 auto;
  background-color: ${(props) => props.theme.colors.gray.lighter};
  
   ${Media.greaterThan("700px")`
    width: 100%;
    margin: 0;
  `}
  
  ${Media.greaterThan("large")`
    height: 39rem;
  `}

`;

const NavButtonLink = Styled.a`
  margin-bottom: 10rem;

  ${Media.greaterThan("700px")`
    margin-bottom: 0;
  `}
`;

type HomeCTASectionProps = {
  tagline?: string;
  headline: string;
  description: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
  imageAlt: string;
  tagAsTitle?: boolean;
};

export default ({
  tagline,
  headline,
  description,
  imageA,
  imageB,
  imageAlt,
  tagAsTitle,
}: HomeCTASectionProps) => (
  <Container>
    <Content>
      <Columns>
        <Column>
          <Text>
            {tagline && tagAsTitle && (
              <TaglineExtended as="h1">{tagline}</TaglineExtended>
            )}
            {tagAsTitle && <Headline as="h2">{headline}</Headline>}

            {tagline && !tagAsTitle && (
              <TaglineExtended>{tagline}</TaglineExtended>
            )}
            {!tagAsTitle && <Headline>{headline}</Headline>}

            <DescriptionExtended>{description}</DescriptionExtended>
            <NavButtonLink
              href={`https://${constants.APP_DOMAIN}/auth/sign-up`}
            >
              <Button solid>Sign up for free</Button>
            </NavButtonLink>
          </Text>
        </Column>

        <ColumnIllustration>
          <Box />
          <ImageWrapper>
            <MediaQuery lessThan="medium">
              <ImageMobile
                fluid={"fluid" in imageB ? imageB.fluid : null}
                alt={imageAlt}
              />
            </MediaQuery>

            <MediaQuery greaterThan="medium">
              <ImageDesktop
                fluid={"fluid" in imageA ? imageA.fluid : null}
                alt={imageAlt}
              />
            </MediaQuery>
          </ImageWrapper>
        </ColumnIllustration>
      </Columns>
    </Content>
  </Container>
);
