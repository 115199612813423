import * as React from "react";
import Styled from "styled-components/macro";
import { graphql } from "gatsby";

import Meta from "../../../components/meta";
import Layout from "../../../components/layout/Layout";
import HomeConversion from "../../../components/pages/home/HomeConversion";
import HomeWireframe from "../../../components/pages/home/HomeWireframe";
import HomeBrainstorming from "../../../components/pages/home/HomeBrainstorming";
import HomeCTASection from "../../../components/pages/home/HomeCTASection";
import HomeFeatured from "../../../components/pages/home/HomeFeatured";
import Shoutout from "../../../components/pages/signup/Shoutout";
import Content from "../../../components/layout/Content/Content";

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const Wireframing = ({ data }) => (
  <Layout>
    <Meta
      title="Easy Wireframing With Uizard | Wireframe Design | Uizard"
      description="Drag-and-drop components and templates to design mobile apps, web apps, websites, and desktop software. It's quick and easy! Try it today."
      url="/lp/wireframing/"
      noindex={true}
    />

    <HomeCTASection
      headline="Create interactive wireframes"
      description="Drag-and-drop elements to design user interfaces. Start from scratch or choose a pre-designed web or app template."
      imageA={data.prototypingImageA.childImageSharp}
      imageB={data.prototypingImageA.childImageSharp}
      imageAlt="Use wireframe mode to drag and drop pre-made templates and components to design low-fidelity prototypes in Uizard."
    />

    <HomeBrainstorming
      headline="Real-time collaboration"
      tagline="Get feedback instantly"
      description="Collaborating with your team in Uizard is fast and easy.
      Simply share a link to your project and start designing interactive prototypes collaboratively straight from your web browser.
      No software install required, just pure productivity."
      imageA={data.brainstormingImageA.childImageSharp}
      imageB={data.brainstormingImageB.childImageSharp}
      imageAlt="Image of a team collaborating in Uizard prototyping editor in wireframe mode and writing comments."
    />

    <HomeWireframe
      tagline="Design Tool"
      headline="From wireframe to mockup in one click"
      description="Disable wireframe mode at any time to render your project as a high-fidelity mockup.
      Test different styles instantly with the power of Uizard themes to experiment with your team or simply to explore ideas."
      imageA={data.wireframeImageA.childImageSharp}
      imageB={data.wireframeImageB.childImageSharp}
      imageAlt="Uizard supports wireframe mode to render designs in low-fidelity or as high-fidelity mockups."
      videoName="uizard-low-fi-mode"
      link="/product/"
    />

    <HomeConversion
      tagline="Beyond wireframing"
      headline="Scan your paper wireframes — automagically"
      description="If you and your team sketch your ideas with pen and paper, Uizard can automatically transform
      your notebook scribbles into customizable screens for your web or mobile project. No more lost sketches!"
      imageA={data.conversionImageA.childImageSharp}
      imageB={data.conversionImageB.childImageSharp}
      imageAlt="Transform rough wireframe sketches to digital screens with Uizard."
      link="/design-assistant/"
    />

    <HomeFeatured />

    <ContentExtended>
      <Shoutout
        headline="Design made easy"
        description="Empower your entire team today"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </ContentExtended>
  </Layout>
);

export default Wireframing;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    prototypingImageA: file(
      relativePath: { eq: "wireframing/prototyping-lowfi-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 722, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    prototypingImageB: file(
      relativePath: { eq: "wireframing/prototyping-lowfi-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageA: file(
      relativePath: { eq: "wireframing/conversion-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 938, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageB: file(
      relativePath: { eq: "wireframing/conversion-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeImageA: file(
      relativePath: { eq: "wireframing/hi-fi-mode-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeImageB: file(
      relativePath: { eq: "wireframing/hi-fi-mode-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    brainstormingImageA: file(
      relativePath: { eq: "wireframing/brainstorming-low-fi-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    brainstormingImageB: file(
      relativePath: { eq: "wireframing/brainstorming-low-fi-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1016, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
